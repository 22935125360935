import SectionHeader from "../SectionHeader";
import Cards from "./Cards";
import Profile from "./Profile";

const About = () => {
    return (
        <section className="section-body" id="about">
            <SectionHeader>
                {" "}
                <span className="border-gray-700 border-b-4 p-2 text-gray-700">
                    About
                </span>{" "}
            </SectionHeader>
            <div className="mx-32 my-10">
                <Cards />
            </div>
            <div className="">
                <Profile />
            </div>
        </section>
    );
};

export default About;
