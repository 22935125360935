import navItems from "./navItems";

const Navbar = () => {
    const handleClick = (e) => {
        const location = document.getElementById(e).offsetTop;

        if (location !== 0) {
            window.scrollTo({
                left: 0,
                top: location - 64,
            });
        } else {
            window.scrollTo({
                left: 0,
                top: 0,
            });
        }
    };

    return (
        <div className=" sticky top-0 w-full px-20 h-16 flex bg-gray-900 text-white z-10 items-center border-b-4 border-green-500">
            {navItems.map((item) => {
                return (
                    <div
                        className="p-2 m-3 uppercase tracking-wide text-lg cursor-pointer hover:text-green-500 transition duration-500"
                        key={item.id}
                        onClick={() => handleClick(item.name)}
                    >
                        {item.name}
                    </div>
                );
            })}
        </div>
    );
};

export default Navbar;
