import React from "react";

import ProfilePic from "../../assets/images/profile_image.jpeg";
import RateSkill from "./Rating";

const Profile = () => {
    const handleClick = (e) => {
        const location = document.getElementById(e).offsetTop;

        if (location !== 0) {
            window.scrollTo({
                left: 0,
                top: location - 64,
            });
        } else {
            window.scrollTo({
                left: 0,
                top: 0,
            });
        }
    };
    return (
        <div className="flex w-full bg-white">
            <div className="w-1/2 p-5">
                <div
                    className="profile_image"
                    style={{
                        backgroundImage: `url(${ProfilePic})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>
                <div className="text-center">
                    <span className="text-2xl font-bold text-gray-500 my-5 block">
                        Who's this guy?
                    </span>
                    <p>
                        I'm a Front-End Developer for
                        <a
                            className="text-blue-500"
                            href="https://www.gslab.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {" "}
                            GS Lab{" "}
                        </a>
                        in Pune, Maharastra. <br /> I have serious passion for
                        UI effects,animations and creating intuitive, dynamic
                        user experiences.
                        <br />
                        <div
                            className="text-blue-500 cursor-pointer"
                            onClick={() => handleClick("contact")}
                        >
                            Let's make something special
                        </div>
                    </p>
                </div>
            </div>
            <div className="p-5 w-1/2 justify-center">
                <RateSkill title="HTML" rating={4} />
                <RateSkill title="CSS" rating={4} />
                <RateSkill title="JavaScript" rating={3} />
                <RateSkill title="Typescript" rating={4} />
                <RateSkill title="React" rating={4} />
                <RateSkill title="GIT" rating={3} />
                <RateSkill title="docker" rating={2} />
                <RateSkill title="node" rating={3} />
                <RateSkill title="OIDC" rating={3} />
            </div>
        </div>
    );
};

export default Profile;
