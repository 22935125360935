import Arrow from "../assets/icons/right-arrow.png";

const Hero = () => {
    const onClickHandler = () => {
        const location = document.getElementById("about").offsetTop;

        window.scrollTo({
            left: 0,
            top: location - 64,
        });
    };

    return (
        <div
            id="home"
            className="relative w-full h-screen bg-gray-800 flex text-center items-center justify-center flex-col"
        >
            <div className="font text-white text-lg  sm:text-4xl ">
                Hello, I'm{" "}
                <div className="text-green-500 inline-block">Abhinav Rao</div>.
                <div className="mt-3"> I'm a front end developer.</div>
            </div>
            <div
                className="group px-5 py-2.5 border-2 m-5 flex justify-center items-center text-white sm-text-xl text-lg hover:bg-green-500 transition duration-500 hover:border-green-500 cursor-pointer"
                onClick={onClickHandler}
            >
                <span> View my work </span>
                <div className="transition transform duration-500 w-4 h-4 ml-2 group-hover:rotate-90">
                    <img src={Arrow} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Hero;
