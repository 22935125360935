const navItems = [
    {
        id: 1,
        name: "home",
    },
    {
        id: 2,
        name: "about",
    },
    {
        id: 3,
        name: "portfolio",
    },
    {
        id: 4,
        name: "contact",
    },
];

export default navItems;
