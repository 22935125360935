import FastIcon from "../../assets/icons/fast.png";
import ResponsiveIcon from "../../assets/icons/responsive.png";
import ReuseIcon from "../../assets/icons/reuse.png";
import DynamicIcon from "../../assets/icons/dynamic.png";

const items = [
    {
        id: 1,
        name: "Fast",
        description:
            "Fast load times and lag free interaction, my highest priority.",
        icon: FastIcon,
    },
    {
        id: 2,
        name: "Responsive",
        description: "My layouts will work on any device, big or small.",
        icon: ResponsiveIcon,
    },
    {
        id: 3,
        name: "Reusable",
        description: "Easy to reuse the component for future need",
        icon: ReuseIcon,
    },
    {
        id: 4,
        name: "Dynamic",
        description:
            "Websites don't have to be static, I love making pages come to life.",
        icon: DynamicIcon,
    },
];

const Cards = () => {
    return (
        <div>
            <div className="grid md:grid-cols-4 grid-cols-2 mx-10">
                {items.map((item) => {
                    return (
                        <Card
                            key={item.id}
                            name={item.name}
                            description={item.description}
                            icon={item.icon}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const Card = (props) => {
    return (
        <div className="m-2 p-2 flex flex-col items-center text-center">
            <div className="bg-green-500 relative rounded-full w-24 h-24  p-4 flex justify-center items-center">
                <img src={props.icon} alt="icon" />
            </div>
            <div className="md:text-lg font-bold my-3 text-md">
                {props.name}
            </div>
            <div className="md:text-base sm:text-sm text-xs">
                {props.description}
            </div>
        </div>
    );
};

export default Cards;
