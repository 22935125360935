import SectionHeader from "../SectionHeader";

const Contact = () => {
    return (
        <section className="section-body" id="contact">
            <SectionHeader>
                <span className="border-gray-700 border-b-4 p-2 text-gray-700">
                    Contact
                </span>
            </SectionHeader>
        </section>
    );
};

export default Contact;
