import React from "react";

import StarColored from "../../assets/icons/star_colored.png";
import StarUncolored from "../../assets/icons/star_white.png";

const RateSkill = React.memo((props) => {
    const { title, rating } = props;

    const stars = [];

    for (let i = 0; i < rating; i++) {
        stars.push(
            <div className="px-2 ">
                <img src={StarColored} alt="colored_star" className="h-8" />
            </div>
        );
    }

    for (let j = 0; j < 5 - rating; j++) {
        stars.push(
            <div className="px-2">
                <img src={StarUncolored} alt="star_white" className="h-8" />
            </div>
        );
    }

    return (
        <div className="w-full p-2 flex ">
            <div className="capitalize font-bold tracking-wide text-2xl w-2/5">
                {title}
            </div>
            <div className="flex">{stars}</div>
        </div>
    );
});

export default RateSkill;
