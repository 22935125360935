import SectionHeader from "../SectionHeader";

import CIAM from "../../assets/images/ciam_tool.png";
import IdentityDesk from "../../assets/images/identity_desk.png";
import WashDrain from "../../assets/images/wash_drain.png";

const Portfolio = () => {
    return (
        <section className="section-body pattern bg-gray-800" id="portfolio">
            <SectionHeader>
                <span className="border-white border-b-4 p-2 text-white">
                    projects
                </span>
            </SectionHeader>

            <div className="flex items-center justify-center">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mx-32 my-16">
                    <div class="max-w-xs w-full">
                        <div class="flex items-center justify-center h-56 bg-white border-b-8 border-green-500 rounded-md overflow-hidden">
                            <img src={CIAM} alt="CIAM" className="h-full" />
                        </div>
                        <a
                            href="https://truelogin.ciam.live/login"
                            target="_blank"
                            rel="noreferrer"
                            class="block bg-gray-700 mt-5 rounded-md overflow-hidden transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                        >
                            <div class="py-2 px-3 text-center text-sm">
                                <p class="text-gray-300">
                                    CIAM ( Customer Identity and Access
                                    Management)
                                </p>

                                <span class="block text-gray-500 mt-2">
                                    truelogin.ciam.live
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="max-w-xs w-full">
                        <div class="flex items-center justify-center h-56 bg-white border-b-8 border-green-500 rounded-md overflow-hidden">
                            <img
                                src={IdentityDesk}
                                alt="Identity Desk"
                                className="h-full"
                            />
                        </div>

                        <div class="block bg-gray-700 mt-5 rounded-md overflow-hidden transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div class="py-2 px-3 text-center text-sm">
                                <p class="text-gray-300">
                                    Identity Desk is a tool for provisioning and
                                    user management.
                                </p>

                                <span class="block text-gray-500 mt-2">
                                    Created on-premise application
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="max-w-xs w-full">
                        <div class="flex items-center justify-center h-56 bg-white border-b-8 border-green-500 rounded-md overflow-hidden">
                            <img src={WashDrain} alt="Wash Drain" />
                        </div>

                        <div class="block bg-gray-700 mt-5 rounded-md overflow-hidden transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div class="py-2 px-3 text-center text-sm">
                                <p class="text-gray-300">
                                    Wash drain is a laundry management app.
                                </p>

                                <span class="block text-gray-500 mt-2">
                                    available on gitlab
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-center mt-12">
                <a
                    class="flex items-center text-white hover:underline hover:text-gray-200"
                    href="https://www.github.com/abhinav941"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span>View More On Github</span>

                    <svg
                        class="h-5 w-5 ml-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                    </svg>
                </a>
            </div>
        </section>
    );
};

export default Portfolio;
